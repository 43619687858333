import http from "./http.service";
import Promisable from "./promisable.service";
import { modalActions } from "redux/slices/modal";
import { BookingType } from "redux/slices/booking";
import { NavigateFunction } from "react-router-dom";
import { getAppDispatch } from "utils/dispatch.util";
import { bookingActions } from "redux/slices/booking";
import { formLoaderActions } from "redux/slices/formLoader";
import ObjectService from "utils/object.util";
import moment from "moment";

const url = "/trip";

const BookingService = {
  addBooking: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.newBooking({ type: "admin", booking: trip }));
      navigate?.(`/booking-details/${trip._id}`);
    }

    return [success, error];
  },
  addDuplicateBooking: async (data: any, navigate?: NavigateFunction) => {
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/duplicate`, data)
    );

    if (success) {
      navigate?.(`/bookings?tab=1`);
    }

    return [success, error];
  },

  updateBooking: async (id: string, data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/update/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.setBooking(trip));
      navigate?.(`/booking-details/${id}`);
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  pickupsign_uload: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();
    http.setMultiPart();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/pickup-sign-upload`, data)
    );

    return [success, error];
  },

  getBooking: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookingActions.setLoader(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.setBooking(trip));
    } else dispatch?.(bookingActions.setBooking({ data: "Not Found" }));

    dispatch?.(bookingActions.setLoader(false));
    return [success, error];
  },
  getBookingSocket: async (id: string) => {
    const dispatch = getAppDispatch();

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.updateBookingDetails(trip));
      dispatch?.(bookingActions.newBooking({ type: "market", booking: trip }));
    }

    return [success, error];
  },

  getBookings: async (type: BookingType, data: any, query: String) => {
    const dispatch = getAppDispatch();

    if (data?.all) dispatch?.(modalActions.setLoading(true));
    else dispatch?.(bookingActions.setLoading({ type, loading: true }));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/${type}${query}`, data)
    );

    if (success) {
      const { trips, count } = success.data.data;

      if (data?.all)
        dispatch?.(
          modalActions.updateData({
            table_data: trips.map((booking: any) => ({
              ID: booking.isPrivate
                ? booking.prv_booking_number
                : booking.booking_number,
              Date: moment(booking.date).format("DD-MM-yy"),
              Time: booking.time,
              "Customer Name": booking.booking_for_someone
                ? `${ObjectService.getFullName(booking.booking_for_details)}`
                : `${ObjectService.getFullName(booking.user_details)}`,
              "Customer Phone": booking.booking_for_someone
                ? booking.booking_for_details.phone
                : booking.user_details.phone,
              "Customer Email": booking.booking_for_someone
                ? booking.booking_for_details?.email
                : booking.user_details?.email,
              "Trip Type": booking.trip_type,
              Pickup: `${booking.pickup.title}, ${booking.pickup.address}`,
              Destination: `${booking.destination.title}, ${booking.destination.address}`,
              Duration: booking.duration,
              Distance: booking.distance_in_km,
              "Service Class": booking.class_name,
              "Payment Status": booking.payment_status,
              "Current Status": booking.current_status,
              "Company Name": booking.partner_details?.company_name,
              "Driver Name": `${ObjectService.getFullName(
                booking.driver_details
              )}`,
              Price: booking.price_details.brutto_price,
              "Driver Price": booking.partner_price_details.netto,
            })),
          })
        );
      else
        dispatch?.(
          bookingActions.setBookings({ type, count, bookings: trips })
        );
    }

    if (data?.all) dispatch?.(modalActions.setLoading(false));
    else dispatch?.(bookingActions.setLoading({ type, loading: false }));

    return [success, error];
  },

  getBookingsRatings: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/ratings`, data)
    );

    if (success) {
      const { data } = success.data;
      dispatch?.(bookingActions.setBookingsRatings(data));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  updateBillingAddress: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/updatebillingaddress/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;

      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  underReviewBooking: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/review/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  cancelReviewBooking: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/review-cancel/${id}`)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  sendToMarket: async (payload: any, type: BookingType) => {
    const { data: id, sendToAll } = payload;
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/sendtomarket/${id}?${sendToAll ? "sendToAll=true" : ""}`)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
      dispatch?.(bookingActions.sendMarket({ id, type, booking: trip }));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  acceptFromMarket: async (id: string, type: BookingType) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/acceptfrommarket/${id}`)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
      dispatch?.(bookingActions.acceptMarket({ id, type, booking: trip }));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  assignBooking: async (id: any, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/assign/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  unassignBooking: async (id: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/unassign/${id}`)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  cancelBooking: async (id: any, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/cancel/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  updateBookingStatus: async (id: any, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/update-status/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  addAdminNotes: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/note/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.setBooking(trip));
    }

    return [success, error];
  },
  generateInvoice: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookingActions.setLoader(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/generate-invoice/${id}`)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(bookingActions.setLoader(false));
    return [success, error];
  },
};

export default BookingService;
